<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/laboratory/banner4.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">耐压绝缘测试</div>
                </div>
                <div class="yongtu">用途：检测产品的安全性能，以评估产品做耐压测试时的漏电电流、绝缘电阻是否符合产品的安全设计要求。</div>
                <div class="canshu">主要技术参数：耐压测试电压：0.1 ~ 5.0（KV）AC 或 DC，漏电流范围：1，AC(0.001 ~ 99.9)，2，DC(0.001 ~ 20.00)，绝缘测试电压：100V,250V,500V，1000V，耐压绝缘测试时间：0.0 ~ 999S</div>
                <div class="shebei">设备：耐压绝缘测试仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">泄漏电流测试</div>
                </div>
                <div class="yongtu">用途：该仪器用于模拟人体对泄漏电流的感知特性，以评估产品的安全性能是否符合设计要求。</div>
                <div class="canshu">主要技术参数：测试工作电压：AC（0 ~ 250）V，泄露电流测试范围：（0 ~ 2）mA（2 ~ 20）mA</div>
                <div class="shebei">设备：泄漏电流仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">ROSH检测</div>
                </div>
                <div class="yongtu">用途：检测产品所含 Sb Br HgCl Cr Cd Pb 的含量是否符合国际要求，以及对人体是否造成伤害。以评估产品的是否符合设计要求。</div>
                <div class="canshu">主要技术参数：测试电压：50KV，测试时间：（5-10）min</div>
                <div class="shebei">设备：X 射线荧光分析仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">X-RAY 检测</div>
                </div>
                <div class="yongtu">用途：检测线路板上的 BGA 、单片机、连接器 、芯片、半导体等元器件的内部焊接，以及检测对元器件本身内部构造的分析。</div>
                <div class="canshu">主要技术参数：测 试 电 压 ：110VAC-230VAC,50Hz/60Hz，测试功率：0.5KW，放大倍率：420*，影像分辨率：208Lp/cm，载物台尺寸：510mm*420mm，最大检查范围：440mm*390mm</div>
                <div class="shebei">设备：X-RAY 检测台</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">紫外光老化试验机</div>
                </div>
                <div class="yongtu">用途：用于检测产品的硅胶面膜、外壳及其他塑料件的耐紫外线辐射能力的测试。</div>
                <div class="canshu">主要技术参数：样品尺寸：29cm*7cm*1cm(长宽厚)，温度范围: RT+10～70℃，湿度范围: 75%～95%RH ，紫外灯波长：280～400nm</div>
                <div class="shebei">设备：紫外光老化试验</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">微观检查</div>
                </div>
                <div class="yongtu">用途：适用于微电子、模具、精密机械、粉末冶金、学校示教等领域。并适宜观察产品表面微观结构。</div>
                <div class="canshu">主要技术参数：拍摄元件:1/1.8 英寸 CMOS 图像传感器 实效像素 1,600 (H) × 1,200 (V)，像素数:1,920 (H) × 1,080 (V) (FHD)*1，可观测图像尺寸:1,600 (H) × 1,200 (V) 像素，扫描系统:逐行扫描，帧率:50 F/s (最大)</div>
                <div class="shebei">设备：显微镜</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">晶体管检测</div>
                </div>
                <div class="yongtu">用途：晶体管特性图示仪是一种能对晶体管的特性参数进行测试的仪器，荧光屏的刻度可以直接观测半导体管的共集电极、共基极和共发射极的输入特性、输出特征、转换特征、β参数以及α参数等，并可根据需要，测量半导体管的其他各项极限特性与击穿特性参数。</div>
                <div class="canshu">主要技术参数：最大集电极电流：100A，数据存储：本机可存储10幅图形，也可以通过USB接口无限量存储至电脑。</div>
                <div class="shebei">设备：晶体管图示仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">试样镶嵌</div>
                </div>
                <div class="yongtu">用途：用于非整形，不易手拿的微小金相试样，采用热固性塑料压制的的方式成型。</div>
                <div class="canshu">主要技术参数：外形尺寸：（350*350*400）mm</div>
                <div class="shebei">设备：金相试样镶嵌机</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">试样磨抛</div>
                </div>
                <div class="yongtu">用途：MPD-2W型交流变频无极调试动力金相，适用于金相类，岩相类等试样的粗磨，细磨，打光，抛光。</div>
                <div class="canshu">主要技术参数：磨盘直径：Φ230mm，磨盘转速：（100-1450）r/min</div>
                <div class="shebei">设备：金相试样磨抛机</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        .item{
            margin-bottom: 30px;
            .titles{
                display: flex;
                position: relative;
                .ticon{
                    width: 10px;
                    height: 10px;
                    background: #0052D9;
                    border-radius: 50%;
                    z-index: 2;
                    margin-top: 10px;
                }
                .ticon2{
                    width: 10px;
                    height: 10px;
                    background: #2DC84D;
                    border-radius: 50%;
                    position: absolute;
                    left: 6px;
                    margin-top: 10px;
                }
                .wenzi{
                    font-size: 20px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 30px;
                    margin-left: 20px;
                }
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
                line-height: 30px;
            }
            .canshu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
                line-height: 30px;
            }
            .shebei{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
        }
        
    }
</style>